import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const MenuContainer = styled.div`
    ${tw`
        w-full
        flex
        justify-between
        // bg-yellow-300
        lg:px-[80px]
        mt-5    
        // text-violet-600
        text-sky-600
        font-medium
    `}

`

const ListContainer = styled.div`
    ${tw`
        // bg-gray-300
        md:flex
        hidden
        items-center
        justify-between
        w-full
    `}
    background-color: rgba(225, 255, 255, 0.8)
`
const MenuItems = styled.div`
    ${tw`
        flex
    `}
    ul{
        ${tw`
            flex
            gap-16
            pl-4
        `}
    }
    li{
        ${tw`
            hover:text-slate-600
            hover:cursor-pointer
        `}
    }
`

const RegisterContainer = styled.div`
    ${tw`
        // bg-violet-500
        bg-sky-600
        lg:px-[70px]
        px-[50px]
        py-[20px]
        text-white
        uppercase
    `}
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%)
`

const LandingMenu = () => {
    return(
        <MenuContainer>
            <ListContainer>    
                <MenuItems>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="#offering">About</a></li>
                        {/* <li>Team</li> */}
                        <li><a href="#pricing">Pricing</a></li>
                        <li><a href="#enquiry">Contact</a></li>
                    </ul>
                </MenuItems>
                <RegisterContainer>
                    <Link to="/register">Register Now</Link>
                </RegisterContainer>
            </ListContainer>
        </MenuContainer>
    )
}

export default LandingMenu