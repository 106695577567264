import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const ComingSoon = styled.div`
    ${tw`
        lg:px-[115px]
        px-[20px]
    `}
    h2{
        ${tw`
            font-Raleway
            font-bold
            md:mt-[20px]
            mt-[60px]
            // text-violet-600
            text-sky-600
            md:text-[2.8rem]
            text-[1.8rem]
        `}
    }
    a{
        ${tw`
            px-10
            py-2
            bg-amber-400
            uppercase
            text-white
            // hover:text-violet-600
            hover:text-sky-600
        `}
    }
`

const WelcomeText = styled.div`
    ${tw`
        flex
        flex-col
        text-amber-600
        lg:mt-14
        md:mt-10
        mt-5
        md:w-[45%]
    `}
    h2{
        ${tw`
            md:text-3xl
            text-2xl
            font-bold
            font-Raleway
        `}
    }
    p{
        ${tw`
            mt-3
            font-medium
        `}
    }
`

const PendingTime = styled.div`
    ${tw`
        font-bold
        text-4xl
        my-5
        flex
        items-center
        md:justify-start
        justify-center
        gap-[2px]
        font-Poppins
        // text-violet-600
        text-sky-600
    `}
    div{
        ${tw`
            // bg-[rgba(124,58,237, 0.8)]
            bg-[rgba(2,132,199, 0.8)]
            text-white
            p-3
            rounded-sm
            h-16
            w-16
            flex
            flex-col
            items-center
            text-center
            justify-center
        `}
    }
    p{
        ${tw`
            text-xs
        `}
    }
`

const LandingSection = () => {
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    const deadline = "December, 01, 2023"

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now()
        setDays(Math.floor(time/(1000 * 60 * 60 * 24)))
        setHours(Math.floor((time/(1000 * 60 * 60)) % 24))
        setMinutes(Math.floor((time / 1000 / 60) % 60))
        setSeconds(Math.floor((time/1000) % 60))
    }

    useEffect(()=>{
        const interval = setInterval(()=>{
            getTime(deadline)
        },1000)
        return() =>clearInterval(interval)
    },[])

    return(
        <ComingSoon>
            <WelcomeText>
                <h2>Welcome to eSkole</h2>
                <p>The best online cloud-based solution to manage the end to end operations of the schools, colleges and universities </p>
            </WelcomeText>
            <h2>Launching Soon ...</h2>
            {/* <PendingTime>
                <div>
                    <span>{days}</span>
                    <p>Days</p>
                </div> :
                <div>
                    <span>{hours}</span>
                    <p>Hours</p>
                </div> :
                <div>
                    <span>{minutes}</span>
                    <p>Mins</p>
                </div> :
                <div>
                    <span>{seconds}</span>
                    <p>Secs</p>
                </div>
            </PendingTime> */}
            <a href="#enquiry">Enquire</a>
        </ComingSoon>
    )
}

export default LandingSection