import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {FaRupeeSign} from "react-icons/fa"
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai"


const PricingContainer = styled.div`
    ${tw`
        w-full
    `}
`

const PricingHeader = styled.h2`
    ${tw`
        text-sky-600
        text-3xl
        lg:px-[115px]
        px-[30px]
        bg-sky-100
        py-5
        font-Raleway
    `}
`

const PlansContainer = styled.div`
    ${tw`
        bg-sky-100
        lg:px-[115px]
        px-0
        px-[30px]
        pb-10
        flex
        text-center
    `}
`

const PlansGrid = styled.div`
    ${tw`
        grid
        md:grid-cols-2
        grid-cols-1
        text-center
        m-auto
        w-full
    `}
`

const Col = styled.div`
    ${tw`
        w-full
        flex
        flex-col
        // px-[200px]
        // bg-red-200
        md:bg-sky-600
        md:text-white
        text-sky-600
        py-5
    `}
    h2{
        ${tw`
            text-2xl
            font-Raleway
        `}
    }
`

const PriceTag = styled.div`
    ${tw`
        w-full
        flex
        items-center
        text-center
        justify-center
        font-Poppins
        font-bold
        text-2xl
        py-3
    `}
`

const PriceFreq = styled.div`
    ${tw`
        flex
        text-xs
        md:text-white
        text-sky-600
        pt-2
    `}
`

const FeatureList = styled.div`
    ${tw`
        flex
        w-full
        // bg-red-400
        md:px-10
    `}
    ul{
        ${tw`
            w-full
            lg:px-16
            px-3
        `}
    }
`

const FeatureItem = styled.div`
    ${tw`
        flex
        items-center
        text-start
        justify-between
        w-full
        gap-3
        text-sm
    `}
`

const PricingSection = () => {
    return(
        <PricingContainer id="pricing">
            <PricingHeader>
                Pricing
            </PricingHeader>
            <PlansContainer>
                <PlansGrid>
                    <Col>
                        <h2>BASIC</h2>
                        <PriceTag>
                            <span>
                                15.00
                            </span>
                            <span><FaRupeeSign /></span>
                            <PriceFreq>/ student / month</PriceFreq>
                        </PriceTag>
                        <FeatureList>
                            <ul>
                                <li>
                                    <FeatureItem>
                                        <span>Student Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Attendance Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Fees Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Schedule Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>

                                <li>
                                    <FeatureItem>
                                        <span>Exams and Assignments</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Student Evaluation</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>

                                <li>
                                    <FeatureItem>
                                        <span>Results and Reporting</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Timetable</span>
                                        <span><AiFillCheckCircle color="#fbbf24"/></span>
                                    </FeatureItem>
                                </li>

                                {/* <li>
                                    <FeatureItem>
                                        <span>Staff Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Vendor Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Library Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Collaboration</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Learning Library</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Student Shop</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Acquisition Management and Insights</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Security</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Live Vehcicle Tracking</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Intergrations with attendance systems</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Payroll Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Expense Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Advance Fees Management</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Notifications</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>360 &deg; Analytics</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Lecture Recordings</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Marketing Solutions</span>
                                        <span><AiFillCloseCircle /></span>
                                    </FeatureItem>
                                </li> */}
                                
                                
                            </ul>                         
                        </FeatureList>
                    </Col>
                    <Col>
                        <h2>PREMIUM</h2>
                        <PriceTag>
                            <span>
                                30.00
                            </span>
                            <span><FaRupeeSign /></span>
                            <PriceFreq>/ student / month</PriceFreq>
                        </PriceTag>
                        <FeatureList>
                            <ul>
                                <li>
                                    <FeatureItem>
                                        <span><b>All features included in BASIC, plus</b></span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>

                                <li>
                                    <FeatureItem>
                                        <span>Staff Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Vendor Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Library Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Collaboration</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Learning Library</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Student Shop</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Acquisition Management and Insights</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Digital Security</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Live Vehcicle Tracking</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Intergrations with attendance systems</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Payroll Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Expense Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Advance Fees Management</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Notifications</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>360 &deg; Analytics</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>

                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Lecture Recordings</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                <li>
                                    <FeatureItem>
                                        <span>Marketing Solutions</span>
                                        <span><AiFillCheckCircle color="#fbbf24" /></span>
                                    </FeatureItem>
                                </li>
                                
                                
                            </ul>                         
                        </FeatureList>
                    </Col>
                </PlansGrid>
            </PlansContainer>
        </PricingContainer>
    )
}
    


export default PricingSection