import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import LandingHeader from "./LandingHeader";
import LandingMenu from "./LandingMenu";
import LandingSection from "./LaunchingSection";
import P1 from "../../assets/p2.png"
import Mark from "../../assets/mark.png"
import PricingSection from "./PricingSection";

const HeroContainer = styled.section`
    ${tw`
        w-full
        h-[100vh]
        // bg-violet-100
        bg-sky-100
    `}
    background-repeat:no-repeat;
    background-position: 90% 100%, 90% 40%;
`

const HeroSection = () => {
    return(
        <HeroContainer style={{backgroundImage: `url(${P1}), url(${Mark})`}} >
            <LandingHeader />
            <LandingMenu />
            <LandingSection/>
        </HeroContainer>
    )
}

export default HeroSection